import axios from '@axios'
// import useJwt from '@/auth/jwt/useJwt'
// import { reject, resolve } from 'core-js/fn/promise'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTransactions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/company/transactions', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // handleCheckoutVoucher(ctx, voucherToken) {
    //   return new Promise((resolve, reject) => {
    //     useJwt.checkoutCoupon({ couponToken: voucherToken })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },

    // fetchRewardsMoney(ctx, queryParams) {
    //   return new Promise((resolve, reject) => {
    //     useJwt.listPayments({ params: queryParams })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    // handlePayment(ctx, paymentToken) {
    //   return new Promise((resolve, reject) => {
    //     useJwt.payReward(paymentToken)
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },

    // fetchRewardsVoucher(ctx, queryParams) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get('/rewards/voucher', { params: queryParams })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    // fetchRewardsMoney(ctx, queryParams) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get('/rewards/money', { params: queryParams })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    // fetchIndicate(ctx, { id }) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get(`/rewards/voucher/${id}`)
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },

  },
}
