<template>
  <div :class="{'box__thumbnail' : isThumbnail}">
    <div
      :id="id"
      class="print-a4__banner"
      :style="{backgroundColor: `${primaryColor}`}"
    >
      <div class="wrapper__logo">
        <b-img
          :src="urlLogo"
          :alt="`Logo da empresa ${systemURL}`"
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
        >
          <path
            fill="#ffffff"
            fill-opacity="1"
            d="M0,96L60,133.3C120,171,240,245,360,266.7C480,288,600,256,720,224C840,192,960,160,1080,170.7C1200,181,1320,235,1380,261.3L1440,288L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
          />
        </svg>
      </div>

      <div class="headline__text">
        <h1>
          Ganhe benefícios exclusivos
        </h1>
      </div>

      <div class="wrapper__content">
        <div class="column">
          <div class="qrcode__box">
            <qrcode-vue
              :value="`https://${systemURL}/create-account`"
              :size="isThumbnail ? 70 : 200"
              level="H"
            />
            <!-- <b-img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/QR_Code_Example.svg/1200px-QR_Code_Example.svg.png"
              alt="QR Code"
            /> -->
          </div>

          <div class="link__box">
            <span>
              {{ systemURL }}
            </span>
          </div>
        </div>
        <div class="column">
          <p>Indicando nossos
            produtos e serviços
            para seus amigos</p>
        </div>
      </div>

      <b-img
        src="@/assets/images/marketing/character_high_five.svg"
        alt="Personagens se cumprimentando batendo as mãos"
        class="character__img"
      />
    </div>
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'PrintA4Template',
  components: {
    BImg,
    QrcodeVue,
  },
  props: {
    isThumbnail: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    primaryColor: {
      type: String,
      default: '#FFF',
    },
    urlLogo: {
      type: String,
      default: '',
    },
    systemURL: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;800&display=swap');

    .box__thumbnail {
        border: 1px solid #ccc;
        .print-a4__banner {
            width: 210px;
            height: 297px;

            .wrapper__logo {
                img {
                    max-width: 55px;
                }
            }

            .headline__text {
                h1 {
                    font-size: 1em;
                    line-height: 120%;
                }
            }

            .wrapper__content {
                .column {
                    .link__box {
                    span { font-size: 0.4em; }
                    }

                    p {
                        font-size: 0.6em;
                        line-height: 120%;
                    }
                }
            }
        }
    }

    .print-a4__banner {
        position: relative;
        overflow: hidden;
        width: 595.266px;
        height: 841.8762px;

        .wrapper__logo {
            position: relative;
            width: 100%;
            height: 20.19%;
            padding: 5%;

            display: flex;
            align-items: center;
            justify-content: center;

            img {
                position: relative;
                z-index: 1;
                max-height: 120px;
                max-width: 260px;
            }

            svg {
                display: inline-block;
                position: absolute;
                top: 0;
                left: 0;
                height: 125%;
            }

        }

        .headline__text {
            width: 100%;
            height: 20.8%;
            padding: 3.9%;
            margin-top: 5%;
            display: flex;
            align-items: center;
            justify-content: center;

            h1 {
                font-family: 'Poppins', sans-serif;
                font-weight: 800;
                font-size: 3.5em;
                line-height: 140%;
                text-align: right;
                color: #FFF;
            }
        }

        .wrapper__content {
            width: 100%;
            padding: 0 3.9%;
            display: flex;
            flex: 1;

            .column {
                width: 50%;

                .qrcode__box {
                    padding: 5%;
                    margin: 0 auto;
                    background: #FFF;
                    border-radius: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 85%;
                    }
                }

                .link__box {
                    padding: 5% 10%;
                    border-radius: 33px;
                    margin-top: 10%;
                    background: rgba($color: #FFF, $alpha: 0.5);
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    span {
                        color: #FFF;
                        font-size: 1em;
                        color: #fff;
                        font-family: 'Poppins', sans-serif;
                        font-weight: 800;
                    }
                }

                p {
                    text-align: right;
                    font-size: 1.9em;
                    line-height: 140%;
                    color: #fff;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                }
            }

        }

        .character__img {
            position: absolute;
            bottom: -1px;
            right: -9%;
            max-width: 60%;
        }
    }
</style>
