<template>
  <section>
    <b-row class="mb-1 match-height">
      <b-col
        :cols="$can('read', 'settingFinancial') ? 12 : 12"
        class="px-1"
      >
        <b-card>
          <b-overlay
            :show="isAPICallInProgress"
            rounded="sm"
          >
            <b-row no-gutters>
              <b-col
                cols="2"
              >
                <div class="containerLogo">
                  <b-img
                    v-if="!logoPreview"
                    :src="companyData.urlLogo"
                    class="mb-1"
                    alt="svg img"
                  />
                  <b-img
                    v-else
                    :src="logoPreview"
                    alt="svg img"
                    class="mb-1"
                  />

                  <b-button
                    variant="outline-primary"
                    size="sm"
                    :disabled="$can('edit', 'settingBusiness') === false"
                    @click="$refs.logoInput.click()"
                  >
                    Editar logo
                  </b-button>

                  <input
                    ref="logoInput"
                    type="file"
                    accept="image/*"
                    style="display:none"
                    @change="onLogoSelected"
                  >
                  <!-- v-if="$can('edit', 'settingBusiness')" -->
                  <b-button
                    :disabled="$can('edit', 'settingBusiness') === false"
                    variant="primary"
                    class="mt-1"
                    @click="validationFormCompanyInfo"
                  >
                    Salvar
                  </b-button>
                </div>
              </b-col>
              <b-col cols="4">
                <validation-observer ref="companyInfoForm">
                  <b-form
                    class="px-2 form-validate"
                  >
                    <b-form-group
                      label="Nome fantasia"
                      label-for="fantasyName"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="nome fantasia"
                        rules="required"
                      >
                        <b-form-input
                          v-model="companyData.fantasyName"
                          :state="errors.length > 0 ? false:null"
                          type="text"
                          placeholder="Nome fantasia"
                          :disabled="$can('edit', 'settingBusiness') === false"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!--  -->

                    <div class="inputColorGroup">
                      <input
                        v-model="companyData.primaryColor"
                        type="color"
                        class="mr-1"
                        :disabled="$can('edit', 'settingBusiness') === false"
                      >
                      <span>
                        <b-form-group
                          label="Cor primária"
                          label-for="primaryColor"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="cor primária"
                            rules="required"
                          >
                            <b-form-input
                              v-model="companyData.primaryColor"
                              :state="errors.length > 0 ? false:null"
                              type="text"
                              size="sm"
                              placeholder="#000000"
                              :disabled="$can('edit', 'settingBusiness') === false"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </span>
                    </div>

                    <div class="inputColorGroup">
                      <input
                        v-model="companyData.secondaryColor"
                        type="color"
                        class="mr-1"
                        :disabled="$can('edit', 'settingBusiness') === false"
                      >
                      <span>
                        <b-form-group
                          label="Cor secundária"
                          label-for="secondaryColor"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="cor secundária"
                            rules="required"
                          >
                            <b-form-input
                              v-model="companyData.secondaryColor"
                              :state="errors.length > 0 ? false:null"
                              type="text"
                              size="sm"
                              placeholder="#000000"
                              :disabled="$can('edit', 'settingBusiness') === false"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </span>
                    </div>

                  </b-form>
                </validation-observer>
              </b-col>

              <b-col cols="6">
                <b-row>
                  <b-col
                    cols="4"
                    class="my-1"
                  >
                    <p><strong>
                      <feather-icon
                        icon="BriefcaseIcon"
                        size="15"
                      />
                      Empresa
                    </strong></p>
                  </b-col>
                  <b-col
                    cols="8"
                    class="my-1"
                  >
                    <p>{{ companyData.companyName }}</p>
                  </b-col>
                  <b-col
                    cols="4"
                    class="my-1"
                  >
                    <p><strong>
                      <feather-icon
                        icon="StarIcon"
                        size="15"
                      />
                      CPF/CNPJ
                    </strong></p>
                  </b-col>
                  <b-col
                    cols="8"
                    class="my-1"
                  >
                    <p> {{ companyData.documentId }}</p>
                  </b-col>
                  <b-col
                    cols="4"
                    class="my-1"
                  >
                    <p><strong>
                      <feather-icon
                        icon="GlobeIcon"
                        size="15"
                      />
                      APP
                    </strong></p>
                  </b-col>
                  <b-col
                    cols="8"
                    class="my-1"
                  >
                    <p>{{ systemURL }}</p>
                  </b-col>

                </b-row>
              </b-col>

            </b-row>
          </b-overlay>
        </b-card>
      </b-col>

      <b-col
        v-if="false"
        cols="4"
      >
        <b-card>
          <b-overlay
            :show="isAPICallInProgress"
            rounded="sm"
          >
            <b-row>
              <b-col
                cols="6"
              >
                <h5><strong>{{ getCompanyNamePlan }}</strong></h5>
              </b-col>
              <b-col cols="6">
                <b-badge
                  v-if="companyPlan.activeCompany"
                  variant="light-success"
                >
                  Ativo
                </b-badge>
                <b-badge
                  v-if="!companyPlan.activeCompany"
                  variant="light-danger"
                >
                  Bloqueado
                </b-badge>
              </b-col>
              <b-col
                cols="6"
                class="mt-2"
              >
                <p>Leads Disponíveis</p>
              </b-col>
              <b-col
                cols="6"
                class="mt-2"
              >
                <h5><strong>{{ companyPlan.totalLeads }}</strong></h5>
              </b-col>
              <template v-if="companyPlan.planType === 3">
                <b-col
                  cols="6"
                >
                  <p>Leads Excedidos</p>
                </b-col>
                <b-col
                  cols="6"
                >
                  <h5><strong>{{ companyPlan.exceededLeads }}</strong></h5>
                </b-col>
              </template>
              <template v-if="companyPlan.planType !== 4">
                <b-col
                  cols="6"
                >
                  <p>Valor</p>
                </b-col>
                <b-col
                  cols="6"
                >
                  <p><strong>{{ companyPlan.totalPayable }}</strong></p>
                </b-col>
              </template>
              <template v-if="companyPlan.planType !== 3 ">
                <b-col cols="6">
                  <p>Renovação</p>
                </b-col>
                <b-col cols="6">
                  <p><strong>{{ companyPlan.planType === 4 ? 'Manual' : 'Automática' }}</strong></p>
                </b-col>
              </template>
              <b-col cols="6">
                <p>Vencimento</p>
              </b-col>
              <b-col cols="6">
                <p><strong>{{ companyPlan.fineshIn }}</strong></p>
              </b-col>
              <b-col cols="12">
                <b-button
                  variant="primary"
                  class="d-flex justify-content-center"
                  style="width: 100%;"
                  @click="() => this.$router.push({ name: 'company-plan' })"
                >
                  <span class="align-middle">Ver plano</span>
                </b-button>
              </b-col>
            </b-row>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col cols="6">
        <b-card>
          <b-overlay
            :show="isAPICallInProgress"
            rounded="sm"
          >
            <h4 class="mb-2">
              <feather-icon
                icon="ListIcon"
                size="20"
                class="mr-1"
              /> Configuração pagamento de recompensa
            </h4>
            <validation-observer ref="rewardsConfigForm">
              <b-form
                class="px-5 form-validate"
              >
                <app-timeline>
                  <app-timeline-item>
                    <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                      <h6>Mínimo para resgate.</h6>
                    </div>
                    <p>Valor definido para mínimo a ser resgatado.</p>
                    <b-form-group class="inputConfigPayment">
                      <validation-provider
                        #default="{ errors }"
                        name="valor mínimo"
                        rules="required|integer"
                      >
                        <b-input-group
                          prepend="R$"
                          append=",00"
                        >
                          <b-form-input
                            v-model="minValueToRedeem"
                            :state="errors.length > 0 ? false:null"
                            type="text"
                            placeholder="15"
                            :disabled="$can('edit', 'settingBusiness') === false"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </app-timeline-item>

                  <app-timeline-item variant="warning">
                    <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                      <h6>Prazo para pagamento da recompensa.</h6>
                    </div>
                    <p>Em dias úteis.</p>
                    <b-form-group class="inputConfigPayment">
                      <validation-provider
                        #default="{ errors }"
                        name="dias úteis"
                        rules="required|integer"
                      >
                        <b-input-group
                          append="dias úteis"
                        >
                          <b-form-input
                            v-model="daysForPayment"
                            :state="errors.length > 0 ? false:null"
                            type="text"
                            placeholder="3"
                            :disabled="$can('edit', 'settingBusiness') === false"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </app-timeline-item>
                </app-timeline>

                <b-button
                  variant="primary"
                  :disabled="$can('edit', 'settingBusiness') === false"
                  @click="validationFormRewardsInfo"
                >
                  <span
                    class="align-middle"
                  >
                    Salvar
                  </span>
                </b-button>

              </b-form>
            </validation-observer>
          </b-overlay>
        </b-card>
      </b-col>

      <b-col
        cols="6"
      >
        <b-card
          v-if="$can('read', 'configProfile')"
        >
          <h4 class="mb-2">
            <feather-icon
              icon="LockIcon"
              size="20"
              class="mr-1"
            /> Perfis de acesso
          </h4>
          <p>Lista de perfis cadastrados</p>
          <b-overlay
            :show="isAPICallInProgress"
            rounded="sm"
          >
            <b-table
              striped
              responsive
              class="mt-2"
              :items="profileList"
              :fields="tableColumns"
              empty-text="Não existem perfis cadastrados"
              show-empty
            >
              <template #cell(name)="data">
                {{ data.value }}
              </template>
              <template
                #cell(usersAssociated)="data"
              >
                <span style="display: block; text-align: center">{{ data.value }}</span>
              </template>
              <template #cell(actions)="data">
                <!-- <b-button :to="{ name: 'users-edit', params: { id: data.item.userToken } }"> -->
                <b-button
                  v-b-modal.edit-profile
                  variant="flat-primary"
                  class="btn-icon"
                  :disabled="$can('edit', 'configProfile') === false"
                  @click="handleSelectProfile(data.item.id, data.item.name)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>

                <b-button
                  variant="flat-primary"
                  class="btn-icon"
                  :disabled="$can('edit', 'configProfile') === false"
                  @click="deleteProfile(data.item.id)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>

              </template>
            </b-table>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>

    <b-row
      class="match-height"
    >
      <b-col cols="6">
        <b-card>
          <b-overlay
            :show="isAPICallInProgress"
            rounded="sm"
          >
            <h4 class="mb-2">
              <feather-icon
                icon="ToolIcon"
                size="20"
                class="mr-1"
              /> Informações de Suporte no App
            </h4>
            <p>Aqui você pode definir o texto, que vai aparecer no aplicativo dos seus clientes, com informações de suporte, apoio e ajuda ao cliente</p>
            <div class="d-flex">  <b>Deseja ativar essa opção?</b>
              <b-form-checkbox
                v-model="companyData.customerService.showCustomerService"
                checked="true"
                class="custom-control-primary ml-1"
                name="check-button"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </div>
            <validation-observer ref="customerService">
              <validation-provider
                #default="{ errors }"
                name="informações de suporte"
                rules=""
              >
                <template v-if="companyData.customerService.showCustomerService">
                  <quill-editor
                    v-model="companyData.customerService.customerServiceText"
                    :disabled="!companyData.customerService.showCustomerService"
                    class="mt-1"
                    :options="snowOption"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </template>
              </validation-provider>
            </validation-observer>
            <p class="mt-2">
              <b-button
                variant="primary"
                :disabled="$can('edit', 'settingBusiness') === false"
                @click="validationFormCostumerService"
              >
                <span
                  class="align-middle"
                >
                  Salvar
                </span>
              </b-button>
            </p>
          </b-overlay>
        </b-card>
      </b-col>

      <b-col cols="6">
        <b-card>

          <b-overlay
            :show="isAPICallInProgress"
            rounded="sm"
          >
            <h4 class="mb-2">
              <feather-icon
                icon="ImageIcon"
                size="20"
                class="mr-1"
              /> Artes de divulgação
            </h4>
            <b-row>
              <b-col
                cols="6"
                class="d-flex flex-column align-items-center justify-content-between"
              >
                <div class="text-center">
                  <whatsapp-template
                    v-if="companyData"
                    is-thumbnail
                    :primary-color="companyData.primaryColor"
                    :url-logo="companyData.urlLogo"
                    :system-u-r-l="systemURL"
                  />
                  <!--  -->
                  <span
                    id="popover-web-file"
                    class="mt-25"
                  >
                    <small
                      class="text-dark"
                    >Onde utilizar</small>
                    <feather-icon
                      size="12"
                      class="ml-50"
                      icon="HelpCircleIcon"
                    />
                  </span>
                  <!-- popover -->
                  <b-popover
                    target="popover-web-file"
                    variant="primary"
                    triggers="hover"
                    placement="top"
                  >
                    <template #title>
                      <span>Onde utilizar</span>
                    </template>
                    <div class="px-25">
                      <span class="text-dark">Esta arte é indicada para ser utilizada nas redes sociais e whatsapp.</span>
                    </div>
                  </b-popover>
                <!--  -->
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  :disabled="companyData.urlLogo === ''"
                  class="mt-1"
                  variant="primary"
                  @click="downloadArt('downloaded__whatsapp_banner')"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    class="mr-50"
                  />
                  Fazer download
                  <small class="d-flex mt-50">Tamanho: 1080px x 1080px</small>
                </b-button>
              </b-col>
              <b-col
                cols="6"
                class="d-flex flex-column align-items-center justify-content-between"
              >
                <div class="text-center">
                  <print-a-4-template
                    v-if="companyData"
                    is-thumbnail
                    :primary-color="companyData.primaryColor"
                    :url-logo="companyData.urlLogo"
                    :system-u-r-l="systemURL"
                  />
                  <!--  -->
                  <span
                    id="popover-print-file"
                    class="mt-25"
                  >
                    <small
                      class="text-dark"
                    >Onde utilizar</small>
                    <feather-icon
                      size="12"
                      class="ml-50"
                      icon="HelpCircleIcon"
                    />
                  </span>

                  <!-- popover -->
                  <b-popover
                    target="popover-print-file"
                    variant="primary"
                    triggers="hover"
                    placement="top"
                  >
                    <template #title>
                      <span>Onde utilizar</span>
                    </template>
                    <div class="px-25">
                      <span class="text-dark">Esta arte é indicada impressão no formato A4</span>
                    </div>
                  </b-popover>
                <!--  -->
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  :disabled="companyData.urlLogo === ''"
                  class="mt-1"
                  variant="primary"
                  @click="downloadArt('downloaded__printa4_banner')"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    class="mr-50"
                  />
                  Fazer download
                  <small class="d-flex mt-50">Tamanho: A4 (210mm x 297mm)</small>
                </b-button>

              </b-col>
            </b-row>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-if="false">
      <b-col cols="12">
        <h2>Histórico financeiro</h2>
      </b-col>

      <b-col
        cols="12"
        class="mt-2"
      >
        <app-collapse accordion>
          <app-collapse-item title="Cobranças">
            <b-row>

              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Mostar</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>cobranças</label>
              </b-col>
              <!-- Search -->
              <b-col
                cols="12"
                md="6"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block "
                    placeholder="Pesquisar..."
                  />

                </div>
              </b-col>
            </b-row>
            <b-table
              ref="refTransactionListTable"
              striped
              responsive
              class="mt-2"
              :items="fetchTransactions"
              :fields="tableColumnsTransactions"
              empty-text="Não existem cobranças cadastradas"
              show-empty
            >
              <template #cell(plan)="data">
                <strong>{{ data.value }}</strong> <br>
                <small>{{ data.item.qtdIndications }} indicações</small>
              </template>

              <template #cell(dateBilling)="data">
                {{ data.value }}
              </template>

              <template #cell(dateStatus)="data">
                {{ data.value }}
              </template>

              <template #cell(status)="data">
                <b-badge :variant="resolveTransactionStatusBadge(data.value)">
                  {{ data.value }}
                </b-badge>
              </template>

              <template #cell(actions)="data">
                <b-button
                  variant="flat-primary"
                  class="btn-icon"
                  @click="() => data"
                >
                  <feather-icon icon="PrinterIcon" />
                </b-button>

                <b-button
                  variant="flat-primary"
                  class="btn-icon"
                  @click="() => data"
                >
                  <feather-icon icon="FileTextIcon" />
                </b-button>

              </template>
            </b-table>

            <!-- Footer -->
            <div class="mx-2 mb-2">
              <b-row>

                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                  <span class="text-muted">Exibindo {{ dataMeta.from }} até {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
                </b-col>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >

                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalTransactions"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>

                </b-col>

              </b-row>
            </div>
            <!-- pagination -->
          </app-collapse-item>
        </app-collapse>
      </b-col>
    </b-row>

    <!-- modal edit profile -->
    <b-modal
      id="edit-profile"
      hide-footer
      centered
      size="md"
      :title="`Editar Perfil: ${profileSelectedName}`"
    >
      <div class="d-flex">
        <modal-edit-profile
          :profile-id="profileSelectedId"
          :profile-name="profileSelectedName"
        />
      </div>
    </b-modal>
    <!-- modal edit profile -->

    <!-- MODAL DOWNLOAD ARTE -->
    <b-modal
      v-model="downloadModalArt"
      scrollable
      title="Fazer downwload"
      size="lg"
      hide-footer
    >
      <whatsapp-template
        v-if="companyData"
        id="downloaded__whatsapp_banner"
        :primary-color="companyData.primaryColor"
        :url-logo="companyData.urlLogo"
        :system-u-r-l="systemURL"
      />

      <print-a-4-template
        v-if="companyData"
        id="downloaded__printa4_banner"
        :primary-color="companyData.primaryColor"
        :url-logo="companyData.urlLogo"
        :system-u-r-l="systemURL"
      />
    </b-modal>
  </section>

</template>

<script>

import { mapGetters } from 'vuex'

import generateSwatchesColorsBrand from '@/utils/generateSwatchesColorsBrand'
import formatCnpjCpf from '@/utils/formatCpfCnpj'
import removeSpecialChars from '@/utils/removeSpecialChars'

import { ValidationObserver, ValidationProvider, localize } from 'vee-validate'
import { required, integer, max } from '@validations'

import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  BRow, BCol, BFormGroup, BForm, BCard, BButton, BBadge, BFormInput, BInputGroup, BTable, BPagination, BImg, BModal, VBModal, BOverlay, BFormCheckbox, BPopover,
} from 'bootstrap-vue'

import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import Ripple from 'vue-ripple-directive'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from 'vue-select'

import toConvertDateTimezoneToConventionalDate from '@/utils/toConvertDateTimezoneToConventionalDate'
import toConvertDateTimezoneToConventionalTime from '@/utils/toConvertDateTimezoneToConvencionalTime'

import jwtDecode from 'jwt-decode'
import { toJpeg } from 'html-to-image'

import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'

import useTransactionList from './useTransactionList'

import transactionStoreModule from '../transactionStoreModule'

import ModalEditProfile from './ModalEditProfile.vue'
import WhatsappTemplate from '../marketing/templates/WhatsappTemplate.vue'
import PrintA4Template from '../marketing/templates/PrintA4Template.vue'

export default {
  name: 'CompanyAppConfig',
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BBadge,
    BFormInput,
    AppTimeline,
    AppTimelineItem,
    BInputGroup,
    BTable,
    FeatherIcon,
    AppCollapse,
    AppCollapseItem,
    BPagination,
    vSelect,
    BImg,
    ModalEditProfile,
    BModal,
    BOverlay,
    quillEditor,
    BFormCheckbox,
    WhatsappTemplate,
    PrintA4Template,
    BPopover,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      logoPreview: null,
      selectedLogo: null,
      newLogoBase64: '',
      extNewLogo: '',
      profileList: [],
      required,
      integer,
      max,
      companyPlan: {
        exceededLeads: 0,
        totalPayable: 0,
      },
      companyData: {
        urlLogo: '',
        primaryColor: '#000',
        secondaryColor: '#000',
        fantasyName: '',
        customerService: {
          customerServiceText: '',
          showCustomerService: false,
        },
      },
      daysForPayment: '',
      minValueToRedeem: '',
      snowOption: {
        theme: 'snow',
      },
      downloadModalArt: false,
    }
  },
  computed: {
    ...mapGetters({
      isAPICallInProgress: 'api/isAPICallInProgress',
    }),
    getCompanyNamePlan() {
      if (this.companyPlan.planType === 1) return 'Pós-pago'
      if (this.companyPlan.planType === 2) return 'Pré-pago'
      if (this.companyPlan.planType === 3) return 'Enterprise'
      if (this.companyPlan.planType === 4) return 'Free'
      return ''
    },
    systemURL() {
      return jwtDecode(window.localStorage.getItem('accessToken')).SystemUrl
    },
  },
  mounted() {
    localize('pt_BR')
    this.fetchProfileList()
    this.getCompanyInfo()
    this.getCompanyActivePlan()
    this.getRewardsSettings()
  },
  setup() {
    const {
      tableColumnsTransactions,
      fetchTransactions,
      resolveTransactionStatusBadge,
      refTransactionListTable,
      perPage,
      currentPage,
      totalTransactions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
    } = useTransactionList()

    const profileSelectedId = ref('')
    const profileSelectedName = ref('')

    const convertDate = date => toConvertDateTimezoneToConventionalDate(date)
    const convertDateAndTime = date => toConvertDateTimezoneToConventionalTime(date)

    const INDICATE_APP_STORE_MODULE_NAME = 'app-company-config'
    if (!store.hasModule(INDICATE_APP_STORE_MODULE_NAME)) store.registerModule(INDICATE_APP_STORE_MODULE_NAME, transactionStoreModule)
    onUnmounted(() => {
      if (store.hasModule(INDICATE_APP_STORE_MODULE_NAME)) store.unregisterModule(INDICATE_APP_STORE_MODULE_NAME)
    })

    const tableColumns = [
      { key: 'name', label: 'Perfil', sortable: false },
      { key: 'usersAssociated', label: 'Usuários', sortable: false },
      { key: 'actions', label: 'Ações' },
    ]

    // company plan
    function getCompanyActivePlan() {
      useJwt.getActivePlan()
        .then(response => {
          const retorno = response.data.result
          this.companyPlan = {
            activeCompany: retorno.activeCompany,
            totalLeads: retorno.totalLeads,
            fineshIn: toConvertDateTimezoneToConventionalDate(retorno.plan.fineshIn),
            planType: retorno.planType,
            totalPayable: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(retorno.totalPayable),
            exceededLeads: retorno.exceededLeads,
          }
        })
        .catch(error => error)
    }

    // fetch lista de profiles
    function fetchProfileList() {
      return new Promise((resolve, reject) => {
        // usando a api via jwt
        useJwt.listProfile()
          .then(response => {
            this.profileList = response.data.result

            return resolve(response)
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ocorreu um erro ao tentar listar os perfis. Tente novamente',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
              },
            })
            reject(error)
          })
      })
    }

    function handleSelectProfile(profileId, profileName) {
      profileSelectedId.value = profileId
      profileSelectedName.value = profileName
    }

    function deleteProfile(profileId) {
      this.$swal({
        title: 'Tem certeza que deseja excluir este perfil?',
        text: 'Você não poderá reverter isso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, excluir',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          //
          return new Promise((resolve, reject) => {
            useJwt.deleteProfile({ id: profileId })
              .then(response => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Perfil excluído com sucesso!',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                  },
                })
                this.fetchProfileList()
                resolve(response)
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Ocorreu um erro...',
                    text: error.response.data.errors[0],
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                  },
                })
                reject(error)
              })
          })
        }
        return result
      })
    }

    function getCompanyInfo() {
      useJwt.getCompanyInfo()
        .then(response => {
          this.companyData = {
            ...response.data.result,
            customerService: {
              ...response.data.result.customerService,
              customerServiceText: response.data.result.customerService.customerServiceText !== null
                ? response.data.result.customerService.customerServiceText
                : 'Olá, está com dúvida em como resgatar sua recompensa, quer saber quando a sua indicação será concluída. ou teve algum problema com sua conta? Estamos prontos para te ajudar, basta enviar um e-mail para suporte@XXXX.com.br',
            },
            documentId: formatCnpjCpf(response.data.result.documentId),
          }
          return response
        })
    }

    function getRewardsSettings() {
      useJwt.getRewardsSetting()
        .then(response => {
          this.daysForPayment = response.data.result.daysForPayment
          this.minValueToRedeem = response.data.result.minValueToRedeem
        })
    }

    return {
      fetchProfileList,
      fetchTransactions,
      refTransactionListTable,
      perPage,
      currentPage,
      totalTransactions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      tableColumnsTransactions,
      resolveTransactionStatusBadge,
      convertDate,
      convertDateAndTime,
      handleSelectProfile,
      profileSelectedId,
      profileSelectedName,
      deleteProfile,

      // company
      getCompanyInfo,
      getRewardsSettings,
      getCompanyActivePlan,
    }
  },
  methods: {
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    async onLogoSelected(event) {
      const newLogo = event.target.files[0]
      const spliteredName = newLogo.name.split('').reverse()

      this.extNewLogo = `${spliteredName[3]}${spliteredName[2]}${spliteredName[1]}${spliteredName[0]}`

      try {
        const result = await this.toBase64(newLogo)
        // eslint-disable-next-line prefer-destructuring
        this.newLogoBase64 = result.split(',')[1]
      } catch (error) {
        // console.log('error: ', error)
      }

      this.logoPreview = URL.createObjectURL(newLogo)
      this.selectedLogo = newLogo
    },
    validationFormCostumerService() {
      this.$refs.customerService.validate().then(success => {
        if (success) {
          useJwt.updateInfoCompany({
            customerService: {
              customerServiceText: this.companyData.customerService.customerServiceText,
              showCustomerService: this.companyData.customerService.showCustomerService,
            },
          })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Informações atualizadas com sucesso',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.getCompanyInfo()
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Ocorreu um erro',
                  text: 'Não foi possível alterar as configurações de pagamento. Tente novamente',
                  icon: 'AlertOctagonIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    validationFormRewardsInfo() {
      this.$refs.rewardsConfigForm.validate().then(success => {
        if (success) {
          useJwt.updateRewardsSetting({
            daysForPayment: Number(this.daysForPayment),
            minValueToRedeem: Number(this.minValueToRedeem),
          })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Informações atualizadas com sucesso',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.getRewardsSettings()
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Ocorreu um erro',
                  text: 'Não foi possível alterar as configurações de pagamento. Tente novamente',
                  icon: 'AlertOctagonIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    promiseUpdateColorCompany() {
      // Promise UpdateCss
      const cores = generateSwatchesColorsBrand(
        this.companyData.primaryColor,
        this.companyData.secondaryColor,
      )

      return new Promise((resolve, reject) => {
        useJwt.updateColorsCompany({
          ...cores,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    promiseUpdateLogoCompany() {
      // Promise LogoCompany
      return !this.newLogoBase64 ? '' : new Promise((resolve, reject) => {
        useJwt.updateLogoCompany({
          logo: this.newLogoBase64,
          extension: this.extNewLogo,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    promiseUpdateInfoCompany() {
      // Promise InfoCompany
      return new Promise((resolve, reject) => {
        useJwt.updateInfoCompany({
          companyName: this.companyData.companyName,
          fantasyName: this.companyData.fantasyName,
          documentId: removeSpecialChars(this.companyData.documentId),
          primaryColor: this.companyData.primaryColor,
          secondaryColor: this.companyData.secondaryColor,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    validationFormCompanyInfo() {
      this.$refs.companyInfoForm.validate().then(success => {
        if (success) {
          this.promiseUpdateInfoCompany()
            .then(() => this.promiseUpdateColorCompany()
              .then(() => this.promiseUpdateLogoCompany()
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Informações da empresa atualizadas com sucesso',
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                    },
                  })
                  this.getCompanyInfo()
                })
                .catch(error => error)))
            .catch(error => error)
        }
      })
    },
    downloadArt(bannerId) {
      this.downloadModalArt = true

      setTimeout(() => {
        const target = document.getElementById(bannerId)
        toJpeg(target, {
          quality: 1,
        }).then(dataUrl => {
          const link = document.createElement('a')
          link.download = `${bannerId}.jpg`
          link.href = dataUrl
          link.click()

          this.downloadModalArt = false
        }, 1500)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

  .containerLogo{
    display: flex;
    flex-direction: column;
  }

.inputColorGroup {
    input[type="color"] {
      float: left;
        appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      background: none;
      border: 0;
      cursor: pointer;
      height: 40px;
      padding: 0;
      width: 40px;
      margin-left: 10px;
    }

    ::-webkit-color-swatch{
      border: 0;
      border-radius: 999px;
    }

    ::-webkit-color-swatch-wrapper {
      padding: 0;
    }

    ::-moz-color-swatch,
    ::-moz-focus-inner{
      border: 0;
    }

    ::-moz-focus-inner{
      padding: 0;
    }

    span {
      display: inline-flex;
      flex-direction: column;

      input {
          max-width: 90px;
        }
  }
}

.inputConfigPayment {
  max-width: 200px;
}
</style>
