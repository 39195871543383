export default function toConvertDateTimezoneToConventionalDate(date, withTime = false) {
  const newDate = new Date(date)

  if (!withTime) {
    const convertedDate = newDate.toLocaleDateString('pt-BR')
    return convertedDate
  }
  const convertedDate = new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'medium' }).format(newDate)

  return convertedDate
}
