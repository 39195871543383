import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import toConvertDateTimezoneToConventionalDate from '@/utils/toConvertDateTimezoneToConventionalDate'
import toConvertDateTimezoneToConventionalTime from '@/utils/toConvertDateTimezoneToConvencionalTime'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useTrasactionList() {
  // Use toast
  const toast = useToast()

  const refTransactionListTable = ref(null)

  // Table Handlers
  const tableColumnsTransactions = [
    { key: 'idTransaction', label: '#', sortable: true },
    {
      key: 'dateBilling', label: 'Data da cobrança', sortable: true, formatter: value => toConvertDateTimezoneToConventionalTime(value),
    },
    { key: 'plan', label: 'Plano/Pacote', sortable: true },
    {
      key: 'value', label: 'Valor', sortable: true, formatter: value => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    },
    {
      key: 'dateStatus', label: 'Data Status', sortable: true, formatter: value => toConvertDateTimezoneToConventionalDate(value),
    },
    { key: 'status', label: 'Status', sortable: true },
    { key: 'idTransation', label: 'Id Transação', sortable: true },
    { key: 'actions', label: 'Ações' },
  ]

  const perPage = ref(10)
  const totalTransactions = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('dateBilling')
  const isSortDirDesc = ref(false)
  const stageFilter = ref(null)
  // const filterDatesFilter = ref(null)
  // const statusFilter = ref(null)
  // const campaignsFilter = ref(null)
  // const campaignsList = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refTransactionListTable.value ? refTransactionListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTransactions.value,
    }
  })

  const refetchData = () => {
    refTransactionListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchTransactions = (ctx, callback) => {
    const payload = {
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      stage: stageFilter.value,
      // status: statusFilter.value,
      // campaign: campaignsFilter.value,
    }
    store
      .dispatch('app-company-config/fetchTransactions', payload)
      .then(response => {
        // const { rewards, total } = response.data
        if (response.data) {
          const { transactions, total } = response.data
          // const { rewards } = response.data.result
          // const total = response.data.result.qtyRegister
          callback(transactions)
          totalTransactions.value = total
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao carregar a lista de cobranças',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveTransactionStatusBadge = status => {
    if (status === 'Pago') return 'light-success'
    if (status === 'Aguardando pagamento') return 'light-warning'
    if (status === 'Cancelado') return 'light-danger'
    return 'light-primary'
  }

  return {
    fetchTransactions,
    tableColumnsTransactions,
    perPage,
    currentPage,
    totalTransactions,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTransactionListTable,
    resolveTransactionStatusBadge,

    refetchData,

    // Extra Filters
    // statusFilter,
    stageFilter,
    // campaignsFilter,
    // filterDatesFilter,

    // campaignsList,
  }
}
