<script>

import { mapGetters } from 'vuex'

import {
  BRow, BCol, BCardHeader, BCardTitle, BTable, BFormCheckbox, BOverlay, BButton,
} from 'bootstrap-vue'

import { localize } from 'vee-validate'

import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { ref } from '@vue/composition-api'

export default {
  components: {
    BRow, BCol, BCardHeader, BCardTitle, BTable, BFormCheckbox, BOverlay, BButton,
  },
  data() {
    return {
      sortDesc: false,
      sortBy: 'featureName',
      profileList: [],
    }
  },
  computed: {
    ...mapGetters({
      isAPICallInProgress: 'api/isAPICallInProgress',
    }),
  },
  props: {
    profileId: {
      type: String,
      default: () => '',
    },
    profileName: {
      type: String,
      default: () => '',
    },
  },
  mounted() {
    localize('pt_BR')

    this.fetchProfileList()
  },
  setup() {
    // itens do select de perfil
    const optionsProfileSelect = ref([])
    const qtdUserByProfile = ref(0)
    const permissionsData = ref([])

    const permissionDataToUpdate = ref([])

    const permissionsChecked = ref([])

    const tableColumns = [
      { key: 'featureName', label: 'Funcionalidade', sortable: false },
      { key: 'edit', label: 'Editar', sortable: false },
      { key: 'read', label: 'Ver', sortable: false },
    ]

    function handleUpdatePermitions(event, dataPermitions) {
      const permitionObject = {
        // funcionalidades: dataPermitions.item.funcionalidades,
        featureName: dataPermitions.item.featureName,
        featureAlias: dataPermitions.item.featureAlias,
        edit: false,
        read: false,
      }

      const permitionTemp = permissionDataToUpdate.value.filter(permition => permition.featureName === dataPermitions.item.featureName)[0]
      const permitionTempDiff = permissionDataToUpdate.value.filter(permition => permition.featureName !== dataPermitions.item.featureName)

      const newValue = event
      const canChangeIndividual = (dataPermitions.field.key === 'read' && newValue) || (dataPermitions.field.key === 'edit' && !newValue)

      if (permitionTemp) {
        if (canChangeIndividual) {
          permitionTemp[dataPermitions.field.key] = newValue
          permissionDataToUpdate.value = [...permitionTempDiff, permitionTemp]
        } else {
          permitionTemp.edit = newValue
          permitionTemp.read = newValue
        }
      } else {
        permitionObject[dataPermitions.field.key] = newValue
        permissionDataToUpdate.value.push(permitionObject)
      }
    }

    // fetch lista de profiles
    function fetchProfileList() {
      return new Promise((resolve, reject) => {
        // usando a api via jwt
        useJwt.listProfile()
          .then(response => {
            this.profileList = response.data.result
            this.selected = { name: response.data.result[0].name, id: response.data.result[0].id }
            optionsProfileSelect.value = response.data.result.map(profile => ({ name: profile.name, id: profile.id }))

            permissionsData.value = this.profileList.filter(profile => profile.id === this.profileId)[0].featuresProfiles
            permissionDataToUpdate.value = this.profileList.filter(profile => profile.id === this.profileId)[0].featuresProfiles

            // console.log(response)
            return resolve(response)
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ocorreu um erro ao tentar listar os perfis. Tente novamente',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
              },
            })
            reject(error)
          })
      })
    }

    function resolveSelectedProfile() {
      const selectedProfileName = this.selected.name
      qtdUserByProfile.value = this.profileList.filter(profile => profile.name === selectedProfileName)[0].usersAssociated
      permissionsData.value = this.profileList.filter(profile => profile.name === selectedProfileName)[0].featuresProfiles

      permissionDataToUpdate.value = this.profileList.filter(profile => profile.name === selectedProfileName)[0].featuresProfiles
    }

    function verifyCheckedPermissions(permissionObject) {
      if (permissionObject.edit === true || permissionObject.read === true) {
        this.permissionsChecked.push(permissionObject)
      }
    }

    function updateProfilePermissions() {
      //
      this.permissionDataToUpdate.filter(this.verifyCheckedPermissions)

      if (this.permissionsChecked.length >= 1) {
        //
        return new Promise((resolve, reject) => {
          useJwt.updateProfile({
            name: this.profileName,
            featuresProfiles: permissionDataToUpdate.value,
            id: this.profileId,
          })
            .then(response => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Perfil atualizado com sucesso!',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              // chamada para atualização da lista de perfil
              this.fetchProfileList()
              resolve(response)
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Ocorreu um erro ao atualizar o perfil. Tente novamente',
                  icon: 'AlertOctagonIcon',
                  variant: 'danger',
                },
              })
              reject(error)
            })
        })
        //
      }

      return this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Opss...',
          text: 'Você deve adicionar pelo menos uma funcionalidade para o perfil.',
          icon: 'AlertOctagonIcon',
          variant: 'danger',
        },
      })
    }

    return {
      permissionsData,
      tableColumns,
      // prop,
      permissionsChecked,
      handleUpdatePermitions,
      fetchProfileList,
      optionsProfileSelect,
      resolveSelectedProfile,
      qtdUserByProfile,
      permissionDataToUpdate,
      updateProfilePermissions,
      verifyCheckedPermissions,
    }
  },
}
</script>
<template>
  <b-row>
    <b-col cols="12">
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">Permissões</span>
        </b-card-title>
      </b-card-header>
      <b-overlay
        :show="isAPICallInProgress"
        rounded="sm"
      >
        <b-table
          striped
          responsive
          class="mb-0"
          :items="permissionsData"
          :fields="tableColumns"
          :sort-desc.sync="sortDesc"
          :sort-by.sync="sortBy"
        >
          <template #cell(featureName)="data">
            {{ data.value }}
          </template>
          <template #cell(edit)="data">
            <b-form-checkbox
              :checked="data.value"
              :disabled="$can('edit', 'configProfile') === false"
              @change="handleUpdatePermitions($event, data)"
            />

          </template>
          <template #cell(read)="data">
            <b-form-checkbox
              :checked="data.value"
              :disabled="$can('edit', 'configProfile') === false"
              @change="handleUpdatePermitions($event, data)"
            />
          </template>
        </b-table>
        <b-button
          v-if="$can('edit', 'configProfile')"
          variant="primary"
          class="d-flex justify-content-center mt-2"
          style="float: right"
          @click.prevent="updateProfilePermissions"
        >
          <span class="align-middle">Salvar</span>
        </b-button>
      </b-overlay>
    </b-col>
  </b-row>
  <!--  -->
</template>
