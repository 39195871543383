<template>
  <div :class="{'box__thumbnail' : isThumbnail}">
    <div
      :id="id"
      class="whatsapp__banner"
      :style="{backgroundColor: `${primaryColor}`}"
    >
      <div class="wrapper__logo">
        <div class="box_logo">
          <b-img
            :src="urlLogo"
            :alt="`Logo da empresa ${systemURL}`"
          />
        </div>
      </div>

      <div class="box__content-text">
        <h1>
          Ganhe benefícios
          exclusivos
        </h1>
        <p>
          Indicando nossos produtos e serviços para seus amigos
        </p>
        <span>
          {{ systemURL }}
        </span>
      </div>

      <b-img
        src="@/assets/images/marketing/character_01.svg"
        alt="svg img"
        class="character__img"
      />
    </div>
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'

export default {
  name: 'WhatsappTemplate',
  components: {
    BImg,
  },
  props: {
    isThumbnail: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    primaryColor: {
      type: String,
      default: '#FFF',
    },
    urlLogo: {
      type: String,
      default: '',
    },
    systemURL: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;800&display=swap');

    .box__thumbnail {
      border: 1px solid #ccc;
        .whatsapp__banner {
            width: 250px;
            height: 250px;

            .box__content-text {
                padding-top: 17%;

                h1 { font-size: 1.1em; }
                p { font-size: 0.7em; }
                span { font-size: 0.45em; }
            }
        }
    }

    .whatsapp__banner {
        position: relative;
        width: 1080px;
        height: 1080px;
        overflow: hidden;

        .wrapper__logo {
            position: absolute;
            width: 76.388889%;
            height: 79.166667%;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            padding: 0 14.185185% 11.1111111111% 0;
            border-radius: 100%;
            transform: translate(-32.969696969697%, -49.939393939394%);
            top: 0;
            left: 0;
            background-color: rgba($color: #fff, $alpha: 1);

            .box_logo {
                width: 55.888889%;
                height: 30.925926%;
                display: flex;
                align-items: center;
                justify-content: center;

            img {
                max-width: 420px;
                width: 80%;
            }
            }

        }

        .box__content-text {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            position: absolute;
            width: 46.296296296296%;
            height: 100%;
            top: 0;
            right: 0;
            padding-top: 17%;
            padding-right: 6%;
            text-align: right;
            color: #fff;
            font-size: 16px;

            h1 {
                padding: 0;
                font-size: 4.3em;
                line-height: 140%;
                color: #fff;
                font-family: 'Poppins', sans-serif;
                font-weight: 800;
            }

            p {
                padding: 0;
                font-size: 2.4em;
                line-height: 140%;
                color: #fff;
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
            }

            span {
                font-size: 2em;
                color: #fff;
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
            }
        }

        .character__img {
            position: absolute;
            bottom: 0;
            left: 0;
            max-width: 52.777777777778%;
        }
    }
</style>
