export default function toConvertDateTimezoneToConventionalTime(date) {
  const year = date.slice(0, 4)
  const mouth = date.slice(5, 7)
  const day = date.slice(8, 10)

  const hours = date.slice(11, 13)
  const minutes = date.slice(14, 16)
  const seconds = date.slice(17, 19)

  const convertedDate = `${day}/${mouth}/${year} - ${hours}:${minutes}:${seconds}`
  return convertedDate
}
