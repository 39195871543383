function sanitizeRGB(color) {
  return Math.min(Math.round(color), 255)
}
function overlay(a, b, intensity = 1) {
  // intensity = typeof intensity === 'undefined' ? 1 : intensity
  return a.reduce((result, current, index) => {
    // eslint-disable-next-line no-mixed-operators
    let value = (a[index] < 128) ? (2 * b[index] * a[index] / 255) : (255 - 2 * (255 - a[index]) * (255 - b[index]) / 255)
    value = (value * intensity + (a[index] * (1 - intensity)))

    return result.concat(sanitizeRGB(value))
  }, [])
}

function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : null
}

function hexToRgbNew(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)})` : null
}

function rgbToArray(rgbString) {
  const rgb = rgbString.substring(4, rgbString.length - 1)
    .replace(/ /g, '')
    .split(',')
  // eslint-disable-next-line radix
  return rgb.map(item => parseInt(item))
}

/// ////////////
export default function generateSwatchesColorsBrand(primaryColor, secondaryColor) {
  let lista = []

  const originalColors = [
    Object.keys(hexToRgb('#272727')).map(item => hexToRgb('#272727')[item]),
    Object.keys(hexToRgb('#343434')).map(item => hexToRgb('#343434')[item]),
    // rgb,
    Object.keys(hexToRgb('#6c6c6c')).map(item => hexToRgb('#6c6c6c')[item]),
    Object.keys(hexToRgb('#b4b4b4')).map(item => hexToRgb('#b4b4b4')[item]),
  ]

  const colors = ['newColor1', 'newColor2']
  const newColors = {
    newColor1: rgbToArray(hexToRgbNew(primaryColor)),
    newColor2: rgbToArray(hexToRgbNew(secondaryColor)),
  }

  colors.forEach(colorItem => {
    originalColors.forEach(item => {
      lista = [...lista, overlay(item, newColors[colorItem], 1).toString()]
    })
  })

  return {
    brandPrimaryUp: lista[3],
    brandPrimaryPure: lista[2],
    brandPrimaryDown: lista[1],
    brandPrimaryDeep: lista[0],

    brandSecondaryUp: lista[7],
    brandSecondaryPure: lista[6],
    brandSecondaryDown: lista[5],
    brandSecondaryDeep: lista[4],
  }
}
